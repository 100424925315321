exports.components = {
  "component---src-gatsby-theme-ceteicean-components-ceteicean-tsx": () => import("./../../../src/gatsby-theme-ceteicean/components/Ceteicean.tsx" /* webpackChunkName: "component---src-gatsby-theme-ceteicean-components-ceteicean-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-entities-tsx": () => import("./../../../src/templates/entities.tsx" /* webpackChunkName: "component---src-templates-entities-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/homePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-indices-tsx": () => import("./../../../src/templates/indices.tsx" /* webpackChunkName: "component---src-templates-indices-tsx" */),
  "component---src-templates-map-tsx": () => import("./../../../src/templates/map.tsx" /* webpackChunkName: "component---src-templates-map-tsx" */),
  "component---src-templates-news-index-tsx": () => import("./../../../src/templates/newsIndex.tsx" /* webpackChunkName: "component---src-templates-news-index-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-pages-tsx": () => import("./../../../src/templates/pages.tsx" /* webpackChunkName: "component---src-templates-pages-tsx" */),
  "component---src-templates-search-tsx": () => import("./../../../src/templates/search.tsx" /* webpackChunkName: "component---src-templates-search-tsx" */)
}

